import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import $ from 'jquery';
import { get } from "lodash";
import { Link } from "react-router-dom";
import { BSC_EXPLORER, MODE } from "../../_configs";
import { ACTION_CONST, ROUTES, STATUS } from "../../shared/constants";
import { helpers } from "../../shared/utils";
import StakingPortalPage from "../../shared/layout/StakingPortal";

const WithDrawPage = () => {
    const dispatch = useDispatch();
    const [withdrawCurrentStep, setWithdrawCurrentStep] = useState(1);
    const isConnectWallet = useSelector((state) => get(state, "utils.isConnectWallet", false));
    const walletUtils = useSelector((state) => get(state, "utils.walletUtils", null));
    const walletAddress = useSelector((state) => get(state, "utils.walletAddress", ""));
    const stakingInfo = useSelector((state) => get(state, "wallet.stakingInfo", {}));
    const stakingWalletInfo = useSelector((state) => get(state, "wallet.stakingWalletInfo", {}));
    const [acceptTerm, setAcceptTerm] = useState(false);
    const [enableWithDrawBtn, setEnableWithDrawBBtn] = useState(false);
    const [transactionHash, setTransactionHash] = useState("");
    const [bnbBalance, setBnbBalance] = useState(0);
    const [isSubmitOK, setIsSubmitOK] = useState(false);

    /* WITHDRAW */
    useEffect(() => {
        setWithdrawStepActive();
    }, [setWithdrawStepActive, withdrawCurrentStep])


    //get Info when connect wallet
    useEffect(() => {
        if (walletUtils && walletAddress) {

            //check bnb balance
            walletUtils.web3.eth.getBalance(walletAddress).then(data => {
                setBnbBalance(data / 10 ** 18)
            })
        }

    }, [walletUtils, walletAddress])

    //enable withdraw button
    useEffect(() => {
        if (acceptTerm && isConnectWallet && bnbBalance > 0 && !stakingInfo["isStakingPaused"] && stakingWalletInfo["withdrawTimestamp"] > 0 && stakingWalletInfo["withdrawTimestamp"] <= (new Date().getTime())) {
            setEnableWithDrawBBtn(true);
        } else {
            setEnableWithDrawBBtn(false);
        }
    }, [acceptTerm, isConnectWallet, bnbBalance, stakingInfo, stakingWalletInfo])


    const withdrawBack = () => {
        if (withdrawCurrentStep == 1) {
            return;
        } else {
            setWithdrawCurrentStep(withdrawCurrentStep - 1);
        }
    }

    const withdrawNext = () => {

        if (withdrawCurrentStep === 2) {
            if (walletUtils) {
                dispatch({
                    type: ACTION_CONST.REQUEST_SUBMIT
                })
                walletUtils.stakingExecuteWithdrawal(data => {
                    if (data.status === STATUS.STAKING_EXECUTE_WITHDRAWAL_SUCCESS) {
                        dispatch({
                            type: ACTION_CONST.REQUEST_DONE
                        })
                        setIsSubmitOK(true)
                        setTransactionHash(data.txID)
                        setWithdrawCurrentStep(3);
                    }
                    if (data.status === STATUS.STAKING_EXECUTE_WITHDRAWAL_FAIL) {
                        dispatch({
                            type: ACTION_CONST.REQUEST_DONE
                        })

                        dispatch({
                            type: ACTION_CONST.ALERT_FAILS,
                            message: "Withdraw stake fail"
                        })
                    }
                })
            }
        } else {
            if (withdrawCurrentStep == 3) {
                return;
            } else {
                setWithdrawCurrentStep(withdrawCurrentStep + 1);
            }
        }
    }


    const submitDone = () => {
        setWithdrawCurrentStep(1);
        setIsSubmitOK(false);
    }


    function setWithdrawStepActive() {
        $('#withdraw .bs-stepper-header .step').removeClass('active');
        $('#withdraw .bs-stepper-header .line').removeClass('active');
        for (var i = 1; i <= 3; i++) {
            if (i <= withdrawCurrentStep) {
                $('#withdraw #withdrawButtonStep' + i).addClass('active');
                $('#withdraw #withdrawLineStep' + (i - 1)).addClass('active');
            }
        }
        $('#withdraw .bs-stepper-content').hide();
        $('#withdraw #WithdrawStep' + withdrawCurrentStep).show();
    }

    return (
        <StakingPortalPage selectedTab={3}>
            <div className="tab-pane fade show active" id="withdraw" role="tabpanel" aria-labelledby="withdraw-tab">
                <div className="row justify-content-center mb-4">
                    <div className="col-lg-12">
                        <div className="bs-stepper w-100">
                            <div className="bs-stepper-header" role="tablist">
                                <div className="step active" id="withdrawButtonStep1">
                                    <button type="button" className="step-trigger ps-0">
                                        <img src="/images/cir.svg" />
                                        <span className="bs-stepper-label first">Checkpoints</span>
                                    </button>
                                </div>
                                <div className="line" id="withdrawLineStep1" />
                                <div className="step" id="withdrawButtonStep2">
                                    <button type="button" className="step-trigger">
                                        <img src="/images/cir.svg" />
                                        <span className="bs-stepper-label">Initialize Withdrawal</span>
                                    </button>
                                </div>
                                <div className="line" id="withdrawLineStep2" />
                                <div className="step" id="withdrawButtonStep3">
                                    <button type="button" className="step-trigger">
                                        <img src="/images/cir.svg" />
                                        <span className="bs-stepper-label last">Confirmation</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-12">
                        <div className="">
                            <div className="p-sidebar-card-body">
                                <div className="bs-stepper-content" id="WithdrawStep1">
                                    <div className="text-center">
                                        <h4 className="mb-2 pb-1 step-title">Checkpoints</h4>
                                        <p className="step-desc">The following conditions must be met to proceed:</p>
                                    </div>
                                    <div className="row mt-4 pt-3 d-flex justify-content-center">
                                        <div className="col-xl-3 col-lg-6 col-md-6 mb-md-0 mb-3">
                                            <div className={isConnectWallet ? "p-select-card mb-4 selected" : "p-select-card mb-4"}>
                                                <div className="p-select-card-title"><b>Connected with MetaMask</b></div>
                                                <div className="p-select-card-description">If not connected, click the "Connect Wallet" button in the top right corner</div>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-lg-6 col-md-6 mb-md-0 mb-3">
                                            <div className={stakingWalletInfo["withdrawTimestamp"] > 0 && stakingWalletInfo["withdrawTimestamp"] <= (new Date().getTime()) ? "p-select-card mb-4 selected" : "p-select-card mb-4"}>
                                                <div className="p-select-card-title"><b>7 day waiting period elapsed</b></div>
                                                <div className="p-select-card-description"></div>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-lg-6 col-md-6 mb-md-0 mb-3">
                                            <div className={bnbBalance > 0 ? "p-select-card mb-4 selected" : "p-select-card mb-4"}>
                                                <div className="p-select-card-title"><b>BNB available in wallet</b></div>
                                                <div className="p-select-card-description">
                                                    BNB is required to pay transaction fees on the Binance Smart Chain network.<br />
                                                    BNB Balance: {helpers.formatNumberDownRoundWithExtractMax(bnbBalance, 4)}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-lg-6 col-md-6 mb-md-0 mb-3">
                                            <div className={stakingWalletInfo["withdrawTimestamp"] > 0 ? "p-select-card mb-4 selected" : "p-select-card mb-4"}>
                                                <div className="p-select-card-title"><b>You have Unstaked your CrossSwap</b></div>
                                                <div className="p-select-card-description"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-4 text-center">
                                        <div className="form-check">
                                            <input className="form-check-input float-none me-1"
                                                type="checkbox" defaultValue id="flexCheckDefault"
                                                onChange={() => setAcceptTerm(!acceptTerm)} />
                                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                                I have read the&nbsp;
                                                <Link target="_blank" to={ROUTES.TERM_OF_USES}> Terms and Conditions </Link>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="bs-stepper-content" id="WithdrawStep2">
                                    <div className="text-center">
                                        <h4 className="mb-2 pb-1 step-title">Confirm Withdrawal</h4>
                                        <p className="step-desc">In this step, you complete the transaction that withdraws your CrossSwap tokens</p>
                                    </div>
                                </div>
                                <div className="bs-stepper-content" id="WithdrawStep3">
                                    <div className="text-center">
                                        <div className="text-white">
                                            <i className="mdi mdi-shield-check-outline" style={{ fontSize: '70px', lineHeight: 1.3 }} />
                                        </div>
                                        <h4 className="mb-2 pb-1 step-title">Confirmed</h4>
                                        <p className="step-desc">
                                            You have withdrawn your CrossSwap tokens.<br />
                                            If desired, you may check Binance Smart Chain to confirm the transaction.
                                        </p>
                                        <p><a className="p-address" href={`${BSC_EXPLORER[MODE]}/tx/${transactionHash}`} target="blank">{transactionHash}</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mb-3 mt-4 text-center step-buttons">
                    {!isSubmitOK ?
                        <>
                            <button onClick={() => withdrawBack()} type="button"
                                disabled={!enableWithDrawBtn || withdrawCurrentStep === 1}
                                className="btn btn-primary btn-round btn-back-step me-3 px-5"><i className="mdi mdi-arrow-left me-2" />Previous</button>
                            <button onClick={() => withdrawNext()} type="button"
                                disabled={!enableWithDrawBtn || withdrawCurrentStep === 3}
                                className="btn btn-primary btn-round btn-next-step px-5">Next<i className="mdi mdi-arrow-right ms-2" /></button></>
                        :
                        <>
                            <button onClick={() => submitDone()} type="button"
                                className="btn btn-primary btn-round  me-3 px-5">Done</button>
                        </>
                    }
                </div>
            </div>
        </StakingPortalPage>
    );
};

export default WithDrawPage;
