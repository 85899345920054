import { get } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { isMobile } from "web3modal";
import exactMath from 'exact-math';
import { ACTION_CONST, ROUTES } from "../constants";
import { helpers } from "../../shared/utils";
import { changeTab } from "../../redux/action/app";
import $ from 'jquery';

const Header = () => {
  const dispatch = useDispatch();
  const isConnectWallet = useSelector((state) => get(state, "utils.isConnectWallet", false));
  const walletAddress = useSelector((state) => get(state, "utils.walletAddress", false));
  const walletUtils = useSelector((state) => get(state, "utils.walletUtils", null));
  const [bscpadBalance, setBscpadBalance] = useState(0);
  const [init, setInit] = useState(true);

  //set balance
  useEffect(() => {

    if (walletUtils && walletAddress) {

      //get bnb balance
      walletUtils.web3.eth.getBalance(walletAddress).then(balance => {

        dispatch({
          type: ACTION_CONST.GET_BNB_BALANCE,
          data: exactMath.div(balance, 10 ** 18)
        })
      }).catch(err => {
        console.log(err);
      })

      walletUtils.getTokenStakingBalance().then(data => {

        dispatch({
          type: ACTION_CONST.GET_CROSS_SWAPP_WALLET_BALANCE,
          data: data
        })
        setBscpadBalance(data)
      })

      //get staking wallet Info
      walletUtils.getStakingInfoWallet().then(data => {
        // debugger      
        dispatch({ type: ACTION_CONST.GET_STAKING_WALLET_INFO, data: data })
      });


      //Set interval
      if (init) {
        setInit(false)
        const jobGetBalance = setInterval(() => {
          //get bnb balance
          walletUtils.web3.eth.getBalance(walletAddress).then(balance => {
            dispatch({
              type: ACTION_CONST.GET_BNB_BALANCE,
              data: exactMath.div(balance, 10 ** 18)
            })
          })

          //get bscPad balance
          walletUtils.getTokenStakingBalance().then(data => {
            dispatch({
              type: ACTION_CONST.GET_CROSS_SWAPP_WALLET_BALANCE,
              data: data
            })

            setBscpadBalance(data)
          })

        }, 10 * 1000)
        dispatch({ type: ACTION_CONST.SET_JOB_GET_BALANCE, data: jobGetBalance })
      }
    }

  }, [walletAddress, walletUtils]);

  const handleTabChange = () => {
    $('.navbar-toggler').trigger('click');
  }

  return (
    <>
      <nav id="PPNavbar" className="navbar navbar-expand-lg navbar-dark">
        <div className="container-fluid">
          <Link className="navbar-brand d-flex align-items-center" to={ROUTES.STACKING}>
            <img src="/images/logo.png" height="50.5" alt="CrossSwap" className="me-2" />
          </Link>
          {isMobile() &&
            <div className="d-flex align-items-center" style={{ flexWrap: 'nowrap' }}>
              <div className="dropdown d-block d-md-none d-lg-none d-xl-none">


              </div>
              <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>
            </div>
          }

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <NavLink className="nav-link" activeClassName="active" to={ROUTES.STACKING} onClick={handleTabChange}>Stake</NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" activeClassName="active" to={ROUTES.UNSTACKING} onClick={handleTabChange}>Unstake</NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" activeClassName="active" to={ROUTES.WITHDRAW} onClick={handleTabChange}>Withdraw</NavLink>
              </li>
            </ul>
            <ul className={isConnectWallet ? 'navbar-nav ms-auto mb-2 mb-md-0 connected' : 'navbar-nav ms-auto mb-2 mb-md-0'}>
              {
                !isConnectWallet ?
                  <li className="nav-item me-2">
                    <a className="btn btn-primary" href="#" data-bs-toggle="modal" data-bs-target={"#connectWalletModal"}>
                      <i className="mdi mdi-wallet-plus-outline me-1"></i>
                      <span>Connect Wallet</span>
                    </a>
                  </li>
                  :
                  <>
                    <li className="nav-item me-2">
                      <a className="btn btn-primary btn-balance" href="#" data-bs-toggle="modal" data-bs-target="#walletModal">
                        <i className="mdi mdi-wallet-plus-outline me-1"></i>
                        <span>{helpers.formatTransactionHash(walletAddress, 4, 4)}</span> - {helpers.formatNumberDownRoundWithExtractMax(bscpadBalance, 4)} CSWAP
                      </a>
                    </li>
                  </>
              }
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
